<template>
  <div class="container px-5 animate__animated animate__fadeIn">
      
    <div class="row pb-3">
      <div class="col-12">
        <router-link to="/" class="text-muted text-decoration-none">
          <span class="small"><i class="fas fa-arrow-left"></i></span>
          <span class="ml-3">Iniciar Sesión</span>
        </router-link>
      </div>
      </div>
      <div class="row my-5">
      <div class="col-12">
        <h3 class="mb-4">Recuperar Contraseña</h3>
        <input type="text" class="form-control mb-3" placeholder="Email">
        <button class="btn btn-primary btn-block mb-3">Enviar correo de recuperación</button>
      </div>
      </div>
      <div class="row pt-3">
      <div class="col-12"><p class="small text-muted">X-orsystem 2020 &copy; - Single Sign On</p></div>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'Recovery',
}
</script>